<template>
  <div class="contaner">
    <div class="game-info">
        <!-- 头部信息 -->
        <div class="top">
            <span class="title">最新游戏账号</span>
            <div class="game-select">
                <ul>
                    <div v-for="(item,index) in gamesSelectList" :key="item.id">
                        <li @click="gamesSelectHandler(item,index)" :style="{background:currentMenuIndex===index?'#F02233':''}">
                            <span :style="{color:currentMenuIndex===index?'#FFFFFF':''}">{{item.gameName}}</span>
                        </li>
                    </div>
                    <li @click="gamesSelectMoreHandler(null)"><span>更多游戏 ></span></li>
                </ul>
            </div>
        </div>
        <!-- 游戏信息列表 -->
        <div class="game-info-list">
            <ul>
                <!-- 游戏信息 -->
                <template v-if="currentGameInfo!==null">
                    <li  v-for="(item,index) in currentGameInfo" :key="index" @click="clickGameItemHandler(item)">
                        <img :src="item.productCoverUrl" alt="商品主图">
                        <div class="info">
                            <span class="title">{{ item.productIntroduction }}</span>
                            <span class="zone">游戏区 | {{item.gameZone}}</span>
                            <div class="price-box">
                                <span class="icon">￥</span>
                                <span class="price">{{item.productPrice.toFixed(2)}}</span>
                            </div>
                        </div>
                    </li>
                </template>
                <!-- 空白页 -->
                <no-product-page v-else></no-product-page>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {getHotGameAccountProductList} from '@/services/home';
import NoProductPage from '../../components/no-product-page.vue';
export default {
    name:"HomeContentGameInfo",
    components:{
        NoProductPage
    },
    data(){
        return{
            gamesInfoList:[],
            currentGameInfo:null,
            currentMenuIndex:0
        }
    },
    methods:{
        //点击选择游戏
        gamesSelectHandler(gameItem,index){ 
            this.currentMenuIndex = index;
            //游戏信息展示区过滤为当前游戏
            this.selectCurrentGameItem(gameItem.id);
            //菜单滑动，旧版本菜单
            this.$store.commit('home/updateSliderLeft', 179+'px');
        },
        //点击菜单中的更多按钮
        gamesSelectMoreHandler(gameItem){
            this.$router.push({name:'woyaomai',params:{gameItem}});
            this.$store.commit('home/setCurrentGlobalMenuIndex',1);
        },
        //加载首页游戏信息
        async loadHotGameAccountProductList(){
            try{
                const {data} = await getHotGameAccountProductList();
                if(data.code===200){
                    //console.log(data.data)
                    this.gamesInfoList = data.data;
                    //获取第一项的gameId
                    this.selectCurrentGameItem(this.gamesInfoList[0].gameId);
                }
            }catch(err){}
        },
        //菜单中具体选择的游戏
        selectCurrentGameItem(gameId){
            let currentGameItem = null;
            if(this.gamesInfoList.length>0){
                currentGameItem = this.gamesInfoList.filter((item,index)=>{
                    return item.gameId === gameId;
                })
                // console.log('==='+JSON.stringify(currentGameItem))
            }
            this.currentGameInfo = currentGameItem[0].subGameInfoList===null?null:currentGameItem[0].subGameInfoList;
        },
        //点击具体的游戏商品操作
        clickGameItemHandler(item){
            this.$router.push({name:"accountProductDetails",params:{currentProduct:item}})
            this.$store.commit('home/setCurrentGlobalMenuIndex',1);
        }
    },
    computed:{
        ...mapState({
                gamesSelectList:state=>state.home.gameIconList.slice(0,5)
            })
    },
    created(){
        this.loadHotGameAccountProductList();
        this.$store.commit('home/setCurrentGlobalMenuIndex',0);
    }
}
</script>

<style lang="less" scoped>
.contaner{
    width: calc(100vw);
    min-width: 1440px;
    padding-top: 20px;
    
    .game-info{
        margin: 0 auto;
        width: 1200px;
        // height: 1328px;
        border-radius: 4px;
        background: #FFFFFF;
        .top{
            width: 1200px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background:#FFFFFF;
            border-radius: 4px 4px 0 0;
            .title{
                margin-left: 20px;
                margin-top: 14px;
                font-size: 16px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #000000;
                cursor: default;
            }
            .game-select{
                margin-top: 14px;
                ul{
                    list-style-type: none;
                    padding: 0;
                    display: flex;
                    li{
                        list-style: none;
                        width: 100px;
                        height: 36px;
                        background: #FFFFFF;
                        border-radius: 4px;
                        margin-left: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border: 1px solid #EFEFEF;
                        cursor: default;
                        span{
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #000000;
                        }
                        &:hover{
                            background: #F02233;
                            span{
                                color: #FFFFFF;
                            }
                        }
                    }
                    &:last-child{
                        margin-right: 20px;
                    }
                }
            }
        }

        .game-info-list{
            padding: 0 20px;
            ul{
                max-height: 1268px;
                overflow: hidden;
                list-style-type: none;
                padding: 0px;
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 30px;
                li{
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    width: 275px;
                    height: 282px;
                    background: #F8F9FA;
                    border-radius: 4px;
                    margin-top: 20px;
                    margin-right: 20px;
                    cursor: default;
                    img{
                        width: 275px;
                        height: 154px;
                    }
                    .info{
                        padding: 0 14px;
                        display: flex;
                        flex-direction: column;
                        .title{
                            margin-top: 9px;
                            font-size: 14px;
                            font-family: MicrosoftYaHei;
                            color: #131415;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            line-clamp: 2;
                            -webkit-line-clamp: 2;
                            word-break: break-all;
                        }
                        .zone{
                            margin-top: 14px;
                            font-size: 12px;
                            font-family: MicrosoftYaHei;
                            color: #888888;
                        }
                        .price-box{
                            margin-top: 20px;
                            .icon{
                                font-size: 12px;
                                font-family: DingTalk-JinBuTi, DingTalk;
                                font-weight: normal;
                                color: #F02233;
                            }
                            .price{
                                font-size: 16px;
                                font-family: DingTalk-JinBuTi, DingTalk;
                                font-weight: normal;
                                color: #F02233;
                            }
                        }
                    }
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                    &:nth-of-type(n+5){
                        margin-top: 30px;
                    }
                }
            }
        }

    }
}
</style>